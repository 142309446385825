import axiosInstances from '@/libs/axios-instances'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default function linkWithStripe() {
  const { successfulOperationAlert, failedOperationAlert } = handleAlerts()

  const linkInpoWithStripe = id => {
    axiosInstances.entities.post('internalops/AddStripeAccountToEntity', { entity_id: id }).then(() => {
      successfulOperationAlert('Inpo is linked with Stripe successfully')
    }).catch(() => {
      failedOperationAlert('There are problems in linking with stripe. Please check the payment details.')
    })
  }

  return { linkInpoWithStripe }
}
