<template>
  <div>
    <b-row>
      <add-resource
        resource-name="EntitiesController"
        add-route-name="add-inpo"
      />
      <b-col>
        <b-card header="Search">
          <b-row>
            <b-col md="5">
              <search
                placeholder="Name, Email, Phone"
                :search-query.sync="searchQuery"
                @refresh="refreshTable"
              />
            </b-col>
            <b-col
              md="2"
              class="mt-1 mt-md-0"
            >
              <search-button @refresh="refreshTable" />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row align-h="center">
      <div
        v-if="loading"
        class="loader"
      />
    </b-row>

    <b-row>
      <b-col>
        <inpos-table
          ref="parentTable"
          v-bind="{
            fetchData:getInpos,
            isBusy:isBusy,
            currentPage:currentPage,
            perPage:perPage,
          }"
        />
        <pagination
          :total-rows="totalRows"
          :per-page="perPage"
          :current-page.sync="currentPage"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import Search from '@/common/components/common/Table/Search.vue'
import AddResource from '@/common/components/common/Table/AddResource.vue'
import InposTable from '@/common/components/Inpos/InposTable.vue'
import Pagination from '@/common/components/common/Table/Pagination.vue'

export default {
  name: 'InpoList',
  components: {
    SearchButton,
    AddResource,
    Pagination,
    InposTable,
    Search,
  },
  data() {
    return {
      organizationTable: null,
      rows: [],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      searchQuery: '',
      loading: true,
      items: [],
    }
  },
  methods: {
    getInpos() {
      const promise = this.$entities
        .get(`internalops/entity/list/${this.currentPage}/${this.perPage}`, {
          params: {
            search_str: this.searchQuery,
            type: 'Organization',
            // subclassification: 1,
          },
        })
      return promise.then(res => {
        this.totalRows = res.data.data.pagination.total
        const items = res.data.data.data
        return items || []
      }).finally(() => {
        this.loading = false
      })
    },
    refreshTable() {
      this.$refs.parentTable.$refs.table.refresh()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
